import React from 'react'

const TrustedBy = () => {
    return (
        <section className="bg-gray xs-partner-section" style={{ backgroundImage: `url('assets/images/map.png')` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="xs-partner-content">
                            <div className="xs-heading xs-mb-40">
                                <h2 className="xs-mb-0 xs-title">Trusted by the biggest <span>brand.</span></h2>
                            </div>
                            <p>In-kind donations from our donors and partners allow charity: water to pass 100% of public donations straight to water projects. We are deeply grateful for those who have surprised us with their generosity. A big thanks to the following companies and people who have helped make charity: water’s work possible.</p>
                            <a href="#" className="btn btn-primary">
                                join us now
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <ul className="fundpress-partners">
                            <li><a href="#"><img src="assets/images/partner/client_1.png" alt /></a></li>
                            <li><a href="#"><img src="assets/images/partner/client_2.png" alt /></a></li>
                            <li><a href="#"><img src="assets/images/partner/client_3.png" alt /></a></li>
                            <li><a href="#"><img src="assets/images/partner/client_4.png" alt /></a></li>
                            <li><a href="#"><img src="assets/images/partner/client_5.png" alt /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrustedBy