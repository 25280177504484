import React from 'react'
import '../styles/JoinUs.css'
import { Button, Container, Grid, TextField } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import JoinUsBG from '../assets/img/JoinUsBG.svg'
import Gpay from '../assets/img/gpay.svg'

const JoinUs = () => {
    return (
        <div className='join_us_main'>
            <div className='join_us_hero'></div>
            <Container maxWidth="lg">
                <div className='join_us_header'>
                    <img src="/logo.jpg" width="150px" />
                    <h1>Mukhi Charity Trust</h1>
                    <h5>Join Membership Program</h5>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4 className='text_fild_title'>Personal Information</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                        <TextField className='text_fild_input' id="outlined-basic" label="Name" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField className='text_fild_input' id="outlined-basic" label="Mobile number" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField className='text_fild_input' id="outlined-basic" label="Gmail" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider style={{ padding: '0px' }} dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker className='date_picker' label="Date of birth" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <textarea className='text_fild_textarea' rows={4} placeholder='Address' />
                    </Grid>
                    <Grid item xs={12}>
                        <h4 className='text_fild_title'>Business Information</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField className='text_fild_input' id="outlined-basic" label="Occupation" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField className='text_fild_input' id="outlined-basic" label="Mobile number" variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <textarea className='text_fild_textarea' rows={4} placeholder='Business Address' />
                    </Grid>
                </Grid>
                <div className='join_us_footer'>
                    <div className="join_us_button_main">
                        <Button variant='outlined' className='gpay_button'>
                            <img src={Gpay} alt='' width='150px' />
                        </Button>
                    </div>
                    <div className="xs-navs-button join_us_button_main">
                        <button className="btn btn-primary">
                            Join Us
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default JoinUs