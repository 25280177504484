import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import JoinUs from './JoinUs'
import Layout from '../layout/Layout'

const Index = () => {
    return (
        <Layout>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/join-us' element={<JoinUs />} />
            </Routes>
        </Layout>
    )
}

export default Index