import React from 'react'
import Hero from '../Components/Hero';
import Mission from '../Components/Mission';
import Journal from '../Components/Journal';
import TrustedBy from '../Components/TrustedBy';
import Events from '../Components/Events';
import ContectForm from '../Components/ContectForm';
import Projects from '../Components/Projects';
import CTA from '../Components/CTA';
import CharityPress from '../Components/CharityPress';
import PopularCauses from '../Components/PopularCauses';

const Home = () => {
    return (
        <div className="App">
            

            <Hero />
            <Mission />
            <PopularCauses />
            <CharityPress />
            <CTA />
            <Projects />
            <ContectForm />
            <Events />
            <TrustedBy />
            <Journal />
        </div>
    )
}

export default Home