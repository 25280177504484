import React from 'react'

const PopularCauses = () => {
    return (
        <section className="bg-gray waypoint-tigger xs-section-padding">
            <div className="container">
                <div className="xs-heading row xs-mb-60">
                    <div className="col-md-9 col-xl-9">
                        <h2 className="xs-title">Popular Causes</h2>
                        <span className="xs-separetor dashed"></span>
                        <p>FundPress has built a platform focused on aiding entrepreneurs, startups, and <br /> companies raise capital from anyone.</p>
                    </div>
                    <div className="col-xl-3 col-md-3 xs-btn-wraper">
                        <a href className="btn btn-primary">all Causes</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-popular-item xs-box-shadow">
                            <div className="xs-item-header">
                                <img src="assets/images/causes/causes_4.jpg" alt />
                                <div className="xs-skill-bar">
                                    <div className="xs-skill-track">
                                        <p><span className="number-percentage-count number-percentage" data-value="90" data-animation-duration="3500">0</span>%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-item-content">
                                <ul className="xs-simple-tag xs-mb-20">
                                    <li><a href>Food</a></li>
                                </ul>
                                <a href="#" className="xs-post-title xs-mb-30">Splash Drone 3 a Fully Waterproof Drone that floats</a>
                                <ul className="xs-list-with-content">
                                    <li>$67,000<span>Pledged</span></li>
                                    <li><span className="number-percentage-count number-percentage" data-value="90" data-animation-duration="3500">0</span>% <span>Funded</span></li>
                                    <li>3<span>Days to go</span></li>
                                </ul>
                                <span className="xs-separetor"></span>
                                <div className="row xs-margin-0">
                                    <div className="xs-round-avatar">
                                        <img src="assets/images/avatar/avatar_1.jpg" alt />
                                    </div>
                                    <div className="xs-avatar-title">
                                        <a href="#"><span>By</span>Ema Watson</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-popular-item xs-box-shadow">
                            <div className="xs-item-header">
                                <img src="assets/images/causes/causes_5.jpg" alt />
                                <div className="xs-skill-bar">
                                    <div className="xs-skill-track">
                                        <p><span className="number-percentage-count number-percentage" data-value="59" data-animation-duration="3500">0</span>%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-item-content">
                                <ul className="xs-simple-tag xs-mb-20">
                                    <li><a href>Health</a></li>
                                </ul>
                                <a href="#" className="xs-post-title xs-mb-30">The Read Read: Braille Literacy Tool for the Blind</a>
                                <ul className="xs-list-with-content">
                                    <li>$33,600<span>Pledged</span></li>
                                    <li><span className="number-percentage-count number-percentage" data-value="59" data-animation-duration="3500">0</span>% <span>Funded</span></li>
                                    <li>10<span>Days to go</span></li>
                                </ul>
                                <span className="xs-separetor"></span>
                                <div className="row xs-margin-0">
                                    <div className="xs-round-avatar">
                                        <img src="assets/images/avatar/avatar_2.jpg" alt />
                                    </div>
                                    <div className="xs-avatar-title">
                                        <a href="#"><span>By</span>Kene Williamson</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-popular-item xs-box-shadow">
                            <div className="xs-item-header">
                                <img src="assets/images/causes/causes_6.jpg" alt />
                                <div className="xs-skill-bar">
                                    <div className="xs-skill-track">
                                        <p><span className="number-percentage-count number-percentage" data-value="35" data-animation-duration="3500">0</span>%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-item-content">
                                <ul className="xs-simple-tag xs-mb-20">
                                    <li><a href>Education</a></li>
                                </ul>
                                <a href="#" className="xs-post-title xs-mb-30">BuildOne: $99 3D Printer w/ WiFi and Auto Bed Leveling!</a>
                                <ul className="xs-list-with-content">
                                    <li>$12,760<span>Pledged</span></li>
                                    <li><span className="number-percentage-count number-percentage" data-value="35" data-animation-duration="3500">0</span>% <span>Funded</span></li>
                                    <li>66<span>Days to go</span></li>
                                </ul>
                                <span className="xs-separetor"></span>
                                <div className="row xs-margin-0">
                                    <div className="xs-round-avatar">
                                        <img src="assets/images/avatar/avatar_3.jpg" alt />
                                    </div>
                                    <div className="xs-avatar-title">
                                        <a href="#"><span>By</span>Leo Baddabes</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-popular-item xs-box-shadow">
                            <div className="xs-item-header">
                                <img src="assets/images/causes/causes_7.jpg" alt />
                                <div className="xs-skill-bar">
                                    <div className="xs-skill-track">
                                        <p><span className="number-percentage-count number-percentage" data-value="85" data-animation-duration="3500">0</span>%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-item-content">
                                <ul className="xs-simple-tag xs-mb-20">
                                    <li><a href>Charity</a></li>
                                </ul>
                                <a href="#" className="xs-post-title xs-mb-30">DACBerry PRO – Professional Soundcard for Raspberry Pi</a>
                                <ul className="xs-list-with-content">
                                    <li>$99,980<span>Pledged</span></li>
                                    <li><span className="number-percentage-count number-percentage" data-value="85" data-animation-duration="3500">0</span>% <span>Funded</span></li>
                                    <li>2<span>Days to go</span></li>
                                </ul>
                                <span className="xs-separetor"></span>
                                <div className="row xs-margin-0">
                                    <div className="xs-round-avatar">
                                        <img src="assets/images/avatar/avatar_4.jpg" alt />
                                    </div>
                                    <div className="xs-avatar-title">
                                        <a href="#"><span>By</span>Jhung Li</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-popular-item xs-box-shadow">
                            <div className="xs-item-header">
                                <img src="assets/images/causes/causes_8.jpg" alt />
                                <div className="xs-skill-bar">
                                    <div className="xs-skill-track">
                                        <p><span className="number-percentage-count number-percentage" data-value="74" data-animation-duration="3500">0</span>%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-item-content">
                                <ul className="xs-simple-tag xs-mb-20">
                                    <li><a href>Cave</a></li>
                                </ul>
                                <a href="#" className="xs-post-title xs-mb-30">BIKI: First Bionic Wireless Under water Fish Drone</a>
                                <ul className="xs-list-with-content">
                                    <li>$40,000<span>Pledged</span></li>
                                    <li><span className="number-percentage-count number-percentage" data-value="74" data-animation-duration="3500">0</span>% <span>Funded</span></li>
                                    <li>70<span>Days to go</span></li>
                                </ul>
                                <span className="xs-separetor"></span>
                                <div className="row xs-margin-0">
                                    <div className="xs-round-avatar">
                                        <img src="assets/images/avatar/avatar_5.jpg" alt />
                                    </div>
                                    <div className="xs-avatar-title">
                                        <a href="#"><span>By</span>Chirstina Perry</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-popular-item xs-box-shadow">
                            <div className="xs-item-header">
                                <img src="assets/images/causes/causes_11.jpg" alt />
                                <div className="xs-skill-bar">
                                    <div className="xs-skill-track">
                                        <p><span className="number-percentage-count number-percentage" data-value="89" data-animation-duration="3500">0</span>%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-item-content">
                                <ul className="xs-simple-tag xs-mb-20">
                                    <li><a href>Watar</a></li>
                                </ul>
                                <a href="#" className="xs-post-title xs-mb-30">Brilliant After All, A New Album by Rebecca: Help poor people</a>
                                <ul className="xs-list-with-content">
                                    <li>$98,980<span>Pledged</span></li>
                                    <li><span className="number-percentage-count number-percentage" data-value="89" data-animation-duration="3500">0</span>% <span>Funded</span></li>
                                    <li>2<span>Days to go</span></li>
                                </ul>
                                <span className="xs-separetor"></span>
                                <div className="row xs-margin-0">
                                    <div className="xs-round-avatar">
                                        <img src="assets/images/avatar/avatar_6.jpg" alt />
                                    </div>
                                    <div className="xs-avatar-title">
                                        <a href="#"><span>By</span>Prekina William</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PopularCauses