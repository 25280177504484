import React from 'react'
import { useNavigate } from 'react-router-dom'

function Hero() {

    const navigate = useNavigate()

    return (
        <section className="xs-welcome-slider">
            <div className="xs-banner-slider owl-carousel">
                <div className="xs-welcome-content" style={{ backgroundImage: `url(assets/images/slider/slider_1.jpg)` }}>
                    <div className="container">
                        <div className="xs-welcome-wraper color-white">
                            <h2>Hunger is stalking the globe</h2>
                            <p>Hundreds of thousands of children experiencing or witnessing assault <br /> and other gender-based violence.</p>
                            <div className="xs-btn-wraper">
                                <button onClick={() => navigate('/join-us')} className="btn btn-outline-primary">
                                    join us now
                                </button>
                                <button className="btn btn-primary">
                                    <span className="badge"><i className="fa fa-heart"></i></span> Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="xs-black-overlay"></div>
                </div>
                <div className="xs-welcome-content" style={{ backgroundImage: `url(assets/images/slider/slider_2.jpg)` }}>
                    <div className="container">
                        <div className="xs-welcome-wraper color-white">
                            <h2>Hunger is stalking the globe</h2>
                            <p>Hundreds of thousands of children experiencing or witnessing assault <br /> and other gender-based violence.</p>
                            <div className="xs-btn-wraper">
                                <a href="#" className="btn btn-outline-primary">
                                    join us now
                                </a>
                                <a href="#" className="btn btn-primary">
                                    <span className="badge"><i className="fa fa-heart"></i></span> Donate Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="xs-black-overlay"></div>
                </div>
                <div className="xs-welcome-content" style={{ backgroundImage: `url(assets/images/slider/slider_3.jpg)` }}>
                    <div className="container">
                        <div className="xs-welcome-wraper color-white">
                            <h2>Hunger is stalking the globe</h2>
                            <p>Hundreds of thousands of children experiencing or witnessing assault <br /> and other gender-based violence.</p>
                            <div className="xs-btn-wraper">
                                <a href="#" className="btn btn-outline-primary">
                                    join us now
                                </a>
                                <a href="#" className="btn btn-primary">
                                    <span className="badge"><i className="fa fa-heart"></i></span> Donate Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="xs-black-overlay"></div>
                </div>
            </div>
        </section>
    )
}

export default Hero