import React from 'react'

const CTA = () => {
    return (
        <>
            <section className="bg-navy-blue">
                <div className="container-fulid">
                    <div className="xs-feature-content">
                        <h2 className="color-white">Mukhi Charitable Trusts are <span> charity </span> activities are taken place around the world, lets contribute.</h2>
                    </div>
                </div>
            </section>
            <section className="xs-feature-box-fulid">
                <div className="container-fulid">
                    <div className="row xs-feature-box-wraper">
                        <div className="col-md-12 col-lg-4 xs-feature-box">
                            <div className="xs-feature-box-content">
                                <h3 className="color-white">But we’re not done yet. We need your.</h3>
                                <a href="#" className="btn btn-secondary btn-color-alt">
                                    get involved
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 xs-feature-box highlight">
                            <div className="xs-feature-box-content">
                                <h3 className="color-white">Sponsor an entire trending project</h3>
                                <p>For $10,000 or more you can fully fund a water project for a Community. 100% funds clean water projects. 663 million people drink.</p>
                                <a href="#" className="btn btn-secondary btn-color-alt">
                                    Become an Sponsor
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 xs-feature-box">
                            <div className="xs-feature-box-content">
                                <h3 className="color-white">Connect with us on social media</h3>
                                <ul className="xs-social-list">
                                    <li><a href><i className="fa fa-facebook"></i></a></li>
                                    <li><a href><i className="fa fa-twitter"></i></a></li>
                                    <li><a href><i className="fa fa-instagram"></i></a></li>
                                    <li><a href><i className="fa fa-pinterest-p"></i></a></li>
                                    <li><a href><i className="fa fa-google-plus"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CTA