import React from 'react'

const ContectForm = () => {
    return (
        <section className="parallax-window xs-become-a-volunteer xs-section-padding" style={{ backgroundImage: `url('assets/images/backgrounds/volunteer-bg.jpg')` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-lg-7">
                        <div className="xs-volunteer-form-wraper bg-aqua">
                            <h2>Become a Volunteer</h2>
                            <p>It only takes a minute to set up a campaign. Decide what to do. Pick a name. Pick a photo. And just like that, you’ll be ready to start raising money.</p>
                            <form action="#" method="POST" id="volunteer-form" className="xs-volunteer-form">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <input type="text" id="volunteer_name" className="form-control" placeholder="Your Name" />
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="email" id="volunteer_email" className="form-control" placeholder="Your Email" />
                                    </div>
                                    <div className="col-lg-6">
                                        <select name="branch" className="form-control" id="volunteer_brach">
                                            <option value>Select</option>
                                            <option value>Branch</option>
                                            <option value>New york</option>
                                            <option value>washington</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6 xs-mb-20">
                                        <div className="xs-fileContainer">
                                            <input type="file" id="volunteer_cv" className="form-control" name="file" />
                                            <label for="volunteer_cv">Upload Your CV</label>
                                        </div>
                                    </div>
                                </div>
                                <textarea name="massage" id="massage" placeholder="Enter your massage" cols="30" className="form-control" rows="10"></textarea>
                                <button type="submit" className="btn btn-secondary btn-color-alt">apply now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContectForm