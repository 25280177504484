import React from 'react'

const Projects = () => {
    return (

        <section className="xs-section-padding">
            <div className="container">
                <div className="xs-heading xs-mb-70 text-center">
                    <h2 className="xs-mb-0 xs-title">We’ve funded <span>120,00 charity projects</span> for <br /> 20M people around the world.</h2>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="xs-service-promo">
                            <span className="icon-water"></span>
                            <h5>Pure Water <br />For Poor People</h5>
                            <p>663 million people drink dirty water. Learn how access to clean water can improve health, boost local economies.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="xs-service-promo">
                            <span className="icon-groceries"></span>
                            <h5>Healty Food <br />For Poor People</h5>
                            <p>663 million people drink dirty water. Learn how access to clean water can improve health, boost local economies.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="xs-service-promo">
                            <span className="icon-heartbeat"></span>
                            <h5>Medical <br />Facilities for People</h5>
                            <p>663 million people drink dirty water. Learn how access to clean water can improve health, boost local economies.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="xs-service-promo">
                            <span className="icon-open-book"></span>
                            <h5>Pure Education <br />For Every Children</h5>
                            <p>663 million people drink dirty water. Learn how access to clean water can improve health, boost local economies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects