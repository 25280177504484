import React from 'react'

const CharityPress = () => {
    return (
        <section className="xs-section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="xs-text-content xs-pr-20">
                            <h2 className="color-navy-blue">Welcome to Mukhi Charitable Trusts please rise your hand</h2>
                            <p>The Mukhi Charitable Trusts community was named a “Top 25 Best Global Philanthropist” by Barron’s. We beat Oprah. And, Mashable named Mukhi Charitable Trusts something like “the best place to raise money online for your favorite causes.”</p>
                            <blockquote>
                                If you don't understand how fast and easy it is to so long for your favorite charity on FundPress, please try it. <span>How it works</span> page, <span>Contact us</span>.
                            </blockquote>
                            <a href="#" className="btn btn-primary">
                                <span className="badge"><i className="fa fa-heart"></i></span> Donate Now
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="xs-feature-image">
                            <img src="assets/images/features_1.jpg" alt />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="xs-feature-image">
                            <img src="assets/images/features_2.jpg" alt />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CharityPress