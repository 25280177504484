import React from 'react'

const Events = () => {
    return (
        <section className="xs-section-padding">
            <div className="container">
                <div className="xs-heading row xs-mb-60">
                    <div className="col-md-9 col-xl-9">
                        <h2 className="xs-title">Our Events</h2>
                        <span className="xs-separetor dashed"></span>
                        <p>FundPress has built a platform focused on aiding entrepreneurs, startups, and <br /> companies raise capital from anyone.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 row xs-single-event">
                        <div className="col-md-5">
                            <div className="xs-event-image">
                                <img src="assets/images/event/event_1.jpg" alt />
                                <div className="xs-entry-date">
                                    <span className="entry-date-day">27</span>
                                    <span className="entry-date-month">dec</span>
                                </div>
                                <div className="xs-black-overlay"></div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="xs-event-content">
                                <a href="#">Raspberry velbet</a>
                                <p>In a time of overwhelming emotions, sadness, and pain, obligations.</p>
                                <div className="xs-countdown-timer" data-countdown="2020/01/24"></div>
                                <a href="#" className="btn btn-primary">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 row xs-single-event">
                        <div className="col-md-5">
                            <div className="xs-event-image">
                                <img src="assets/images/event/event_2.jpg" alt />
                                <div className="xs-entry-date">
                                    <span className="entry-date-day">15</span>
                                    <span className="entry-date-month">aug</span>
                                </div>
                                <div className="xs-black-overlay"></div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="xs-event-content">
                                <a href="#">Arsenal, the intelligent.</a>
                                <p>In a time of overwhelming emotions, sadness, and pain, obligations.</p>
                                <div className="xs-countdown-timer" data-countdown="2020/08/24"></div>
                                <a href="#" className="btn btn-primary">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 row xs-single-event">
                        <div className="col-md-5">
                            <div className="xs-event-image">
                                <img src="assets/images/event/event_3.jpg" alt />
                                <div className="xs-entry-date">
                                    <span className="entry-date-day">24</span>
                                    <span className="entry-date-month">jan</span>
                                </div>
                                <div className="xs-black-overlay"></div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="xs-event-content">
                                <a href="#">Waterproof drone that</a>
                                <p>In a time of overwhelming emotions, sadness, and pain, obligations.</p>
                                <div className="xs-countdown-timer" data-countdown="2019/05/24"></div>
                                <a href="#" className="btn btn-primary">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 row xs-single-event">
                        <div className="col-md-5">
                            <div className="xs-event-image">
                                <img src="assets/images/event/event_4.jpg" alt />
                                <div className="xs-entry-date">
                                    <span className="entry-date-day">23</span>
                                    <span className="entry-date-month">jun</span>
                                </div>
                                <div className="xs-black-overlay"></div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="xs-event-content">
                                <a href>Braille Literacy Tool for.</a>
                                <p>In a time of overwhelming emotions, sadness, and pain, obligations.</p>
                                <div className="xs-countdown-timer" data-countdown="2020/02/24"></div>
                                <a href="#" className="btn btn-primary">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Events