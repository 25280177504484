import React from 'react'

function Mission() {
    return (
        <section className="waypoint-tigger xs-section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="xs-archive-content">
                            <div className="xs-heading">
                                <h2 className="xs-line-title">Our Missions</h2>
                                <h3 className="xs-title big" data-title="Archived">Archived</h3>
                            </div>
                            <p>We register and regulate charities in England and Wales, to ensure that the public can support charities with confidence. As registrar, we are responsible for maintaining an accurate.</p>
                            <h5>Our strategic priorities up to 2018 are:</h5>
                            <ul className="xs-unorder-list arrow">
                                <li>Protecting charities from abuse or mismanagement</li>
                                <li>Enabling trustees to run their charities effectively</li>
                                <li>Encouraging greater transparency and accountability</li>
                            </ul>
                            <ul className="xs-funfact-list">
                                <li>
                                    <p>
                                        <span className="number-percentage-count number-percentage" data-value="132" data-animation-duration="3500">0</span><sup> + </sup>
                                    </p>
                                    <span>Primary Schools</span>
                                </li>
                                <li>
                                    <p>
                                        <span className="number-percentage-count number-percentage" data-value="19" data-animation-duration="3500">0</span><sup> + </sup>
                                    </p>
                                    <span>Hospitals</span>
                                </li>
                                <li>
                                    <p>
                                        <span className="number-percentage-count number-percentage" data-value="90" data-animation-duration="3500">0</span><sup> + </sup>
                                    </p>
                                    <span>Volunteers</span>
                                </li>
                                <li>
                                    <p>
                                        <span className="number-percentage-count number-percentage" data-value="27" data-animation-duration="3500">0</span><sup> + </sup>
                                    </p>
                                    <span>Winning Awards</span>
                                </li>
                                <div className="clearfix"></div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 row xs-archive-image">
                        <div className="col-md-12 xs-mb-30">
                            <img src="assets/images/archive-img-1.jpg" alt className="rounded" />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <img src="assets/images/archive-img-2.jpg" alt className="rounded" />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <img src="assets/images/archive-img-3.jpg" alt className="rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mission