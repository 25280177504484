import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <header className="xs-header header-transparent">
            <div className="container">
                <nav className="xs-menus">
                    <div className="nav-header">
                        <div className="nav-toggle"></div>
                        <a href="index.html" className="nav-logo">
                            <img src="/logo.jpg"/>
                        </a>
                    </div>
                    <div className="nav-menus-wrapper row">
                        <div className="xs-logo-wraper col-lg-2 xs-padding-0">
                            <a className="nav-brand" href="index.html">
                                <img src="/logo.jpg" style={{ width: '121px' }} />
                            </a>
                        </div>
                        <div className="col-lg-7">
                            <ul className="nav-menu">
                                <li><Link to="/">Home</Link>
                                    {/* <ul className="nav-dropdown">
                                        <li><a href="index.html">Home 1</a></li>
                                        <li><a href="index-v2.html">Home 2</a></li>
                                        <li><a href="index-v3.html">Home 3</a></li>
                                    </ul> */}
                                </li>
                                <li><Link to='/'>Benefits</Link></li>
                                <li><Link to="/">About</Link></li>
                                <li><Link to="/">Causes</Link></li>
                                <li><Link to="/">Events</Link></li>
                                <li><Link to="/">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="xs-navs-button d-flex-center-end col-lg-3">
                            <button className="btn btn-primary">
                                <span className="badge"><i className="fa fa-heart"></i></span> Donate Now
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header