import React from 'react'

const Journal = () => {
    return (
        <section className="xs-section-padding">
            <div className="container">
                <div className="xs-heading row xs-mb-60">
                    <div className="col-md-9 col-xl-9">
                        <h2 className="xs-title">From the Journal</h2>
                        <span className="xs-separetor dashed"></span>
                        <p>It allows you to gather monthly subscriptions from fans to help fund your creative projects. They also encourage their users to offer rewards to fans as a way to repay them for their support.</p>
                    </div>
                    <div className="col-xl-3 col-md-3 xs-btn-wraper">
                        <a href className="btn btn-primary">all Causes</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-box-shadow xs-single-journal">
                            <div className="entry-thumbnail ">
                                <img src="assets/images/blog/blog_1.jpg" alt />
                                <div className="post-author">
                                    <span className="xs-round-avatar">
                                        <img className="img-responsive" src="assets/images/avatar/avatar_1.jpg" alt />
                                    </span>
                                    <span className="author-name">
                                        <a href="#">By Simona</a>
                                    </span>
                                </div>
                            </div>
                            <div className="entry-header">
                                <div className="entry-meta">
                                    <span className="date">
                                        <a href rel="bookmark" className="entry-date">
                                            27th August 2017
                                        </a>
                                    </span>
                                </div>
                                <h4 className="entry-title">
                                    <a href="#">Brilliant After All, A New Album by Rebecca: Help poor people</a>
                                </h4>
                            </div>
                            <span className="xs-separetor"></span>
                            <div className="post-meta">
                                <span className="comments-link">
                                    <i className="fa fa-comments-o"></i>
                                    <a href>300 Comments</a>
                                </span>
                                <span className="view-link">
                                    <i className="fa fa-eye"></i>
                                    <a href>1000 Views</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-box-shadow xs-single-journal">
                            <div className="entry-thumbnail ">
                                <img src="assets/images/blog/blog_2.jpg" alt />
                                <div className="post-author">
                                    <span className="xs-round-avatar">
                                        <img className="img-responsive" src="assets/images/avatar/avatar_2.jpg" alt />
                                    </span>
                                    <span className="author-name">
                                        <a href="#">By Julian</a>
                                    </span>
                                </div>
                            </div>
                            <div className="entry-header">
                                <div className="entry-meta">
                                    <span className="date">
                                        <a href rel="bookmark" className="entry-date">
                                            02 May 2017
                                        </a>
                                    </span>
                                </div>
                                <h4 className="entry-title">
                                    <a href="#">South african pre primary school build for children</a>
                                </h4>
                            </div>
                            <span className="xs-separetor"></span>
                            <div className="post-meta">
                                <span className="comments-link">
                                    <i className="fa fa-comments-o"></i>
                                    <a href>300 Comments</a>
                                </span>
                                <span className="view-link">
                                    <i className="fa fa-eye"></i>
                                    <a href>1000 Views</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="xs-box-shadow xs-single-journal">
                            <div className="entry-thumbnail ">
                                <img src="assets/images/blog/blog_3.jpg" alt />
                                <div className="post-author">
                                    <span className="xs-round-avatar">
                                        <img className="img-responsive" src="assets/images/avatar/avatar_3.jpg" alt />
                                    </span>
                                    <span className="author-name">
                                        <a href="#">By David Willy</a>
                                    </span>
                                </div>
                            </div>
                            <div className="entry-header">
                                <div className="entry-meta">
                                    <span className="date">
                                        <a href rel="bookmark" className="entry-date">
                                            13 January 2017
                                        </a>
                                    </span>
                                </div>
                                <h4 className="entry-title">
                                    <a href="#">Provide pure water for syrian poor people</a>
                                </h4>
                            </div>
                            <span className="xs-separetor"></span>
                            <div className="post-meta">
                                <span className="comments-link">
                                    <i className="fa fa-comments-o"></i>
                                    <a href>300 Comments</a>
                                </span>
                                <span className="view-link">
                                    <i className="fa fa-eye"></i>
                                    <a href>1000 Views</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Journal